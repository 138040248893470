<template>
    <div class="tabs-bar __container">
        <div class="tabs">
            <div class="tab">
            <p>Всі ресторани</p>
            </div>
            <div class="tab">
                <p>🍳 Сніданки</p>
            </div>
            <div class="tab">
                <p>🇬🇪 Грузинські</p>
            </div>
            <div class="tab">
                <p>🇮🇹 Італійські</p>
            </div>
            <div class="tab">
                <p>🇪🇺 Європейські</p>
            </div>
            <div class="tab">
                <p>🥗 Середземноморські</p>
            </div>
        </div>
        <div class="sort">
            <div class="button">
                <img :src="currentTheme !== 'light' ? require('@/assets/icon/header/dark/sort.svg') : require('@/assets/icon/header/sort.png')" alt="sort">
            </div>
        </div>
    </div>

</template>
    
<script>
export default {
};
</script>
    
<style lang="scss" scoped> 
.tabs-bar { 
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 37px 0px 0px 0px;
    overflow-x: auto; 
    white-space: nowrap;
    max-width: calc(100% - 50px);
    .tab {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4;
        padding: 12px 8px 12px 8px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 16px;
        p {
            color: var(--color-tabs-text);
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            text-align: center;
        }

    }

}
.sort { 
    padding-top: 37px;
}


</style>
    