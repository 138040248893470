<template>
    <a :href="`/market/${restaurant.uniq_id}`" class="restaurants__card restaurants-card">
        <div class="restaurants-card__image-ibg">
            <picture>
                <source :srcset="restaurant.main_photo.webp" type="image/webp">
                <source :srcset="restaurant.main_photo.png" type="image/png">
                <img :src="restaurant.main_photo.png" class="restaurant-img">
            </picture>                  
        </div>
        <div class="restaurants-card__content">
            <div class="timer">
                <p>{{ restaurant.restaurant.delivery_params.min_time }} хв</p>
            </div>      
            <h4>{{ restaurant.title }}</h4>  
            <p>
                <svg width="25" height="25">
                  <path d="M18.8941 17.3939L13.5916 22.6979C13.3827 22.907 13.1346 23.0728 12.8615 23.186C12.5884 23.2992 12.2957 23.3574 12.0001 23.3574C11.7045 23.3574 11.4118 23.2992 11.1387 23.186C10.8657 23.0728 10.6176 22.907 10.4086 22.6979L5.10612 17.3939C4.20069 16.4886 3.48244 15.4138 2.99239 14.2308C2.50233 13.0479 2.25007 11.7801 2.25 10.4997C2.24993 9.21927 2.50206 7.95139 2.99198 6.76842C3.48191 5.58546 4.20004 4.51057 5.10537 3.60514C6.0107 2.69971 7.08551 1.98146 8.26842 1.49141C9.45133 1.00136 10.7192 0.749093 11.9996 0.749023C13.28 0.748954 14.5479 1.00108 15.7308 1.491C16.9138 1.98093 17.9887 2.69906 18.8941 3.60439C19.7996 4.50978 20.5179 5.58467 21.008 6.76768C21.4981 7.95069 21.7503 9.21865 21.7503 10.4991C21.7503 11.7796 21.4981 13.0476 21.008 14.2306C20.5179 15.4136 19.7996 16.4885 18.8941 17.3939ZM17.3041 5.19589C15.8974 3.78918 13.9895 2.9974 12.0001 2.9974C10.0107 2.9974 8.10283 3.78768 6.69612 5.19439C5.28941 6.6011 4.49913 8.50901 4.49913 10.4984C4.49913 12.4878 5.28941 14.3957 6.69612 15.8024L12.0001 21.1049L17.3041 15.8039C18.0008 15.1074 18.5534 14.2805 18.9304 13.3705C19.3075 12.4604 19.5015 11.485 19.5015 10.4999C19.5015 9.51481 19.3075 8.53938 18.9304 7.62931C18.5534 6.71924 18.0008 5.89236 17.3041 5.19589ZM12.0001 13.4999C11.6006 13.5089 11.2033 13.4381 10.8316 13.2914C10.4598 13.1448 10.1211 12.9254 9.83536 12.6461C9.54958 12.3668 9.32249 12.0332 9.16741 11.6649C9.01233 11.2966 8.9324 10.901 8.9323 10.5014C8.9322 10.1018 9.01193 9.70619 9.16683 9.33782C9.32172 8.96945 9.54865 8.63574 9.83429 8.35628C10.1199 8.07682 10.4585 7.85724 10.8302 7.71043C11.2019 7.56363 11.5991 7.49256 11.9986 7.50139C12.7825 7.51873 13.5284 7.84223 14.0767 8.40265C14.625 8.96307 14.9321 9.71588 14.9323 10.4999C14.9325 11.2839 14.6257 12.0369 14.0777 12.5976C13.5297 13.1583 12.784 13.4822 12.0001 13.4999Z"/>
                </svg>
                {{ restaurant.restaurant.address }}
            </p>    
        </div>
      </a>
</template>
  
  <script>
  export default {
    props: {
      restaurant: {
        type: Object,
        required: true
      }
    }
  }
  </script>