<template>
    <nav aria-label="Breadcrumb">
      <div class="breadcrumbs__container">
      <ul class="breadcrumbs">
        <li v-for="(breadcrumb, index) in breadcrumbs" :key="breadcrumb.path" class="breadcrumb-item">
          <!-- Якщо це останній елемент, не рендеримо посилання -->
          <span v-if="isLast(index)">{{ breadcrumb.name }}</span>
          <router-link v-else :to="breadcrumb.path">{{ breadcrumb.name }}</router-link>
        </li>
      </ul>
    </div>
    </nav>
  </template>
  
  <script>
  export default {
    name: "BreadcrumbsComponent",
    props: {
      breadcrumbs: {
        type: Array,
        required: true,
        validator(value) {
          return value.every(
            (breadcrumb) => breadcrumb.name && breadcrumb.path
          );
        },
      },
    },
    methods: {
      isLast(index) {
        return index === this.breadcrumbs.length - 1;
      },
    },
  };
  </script>
  

<style>

.breadcrumbs {
  align-items: center;
  display: flex;
  overflow-x: auto;
  padding-left: 8px;
  white-space: nowrap;
  color: var(--text-color);
}

.breadcrumb-item {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  line-height: 20px;
}

.breadcrumb-item a {
  text-decoration: none;
  display: inline-block;
  height: 100%;
  transition: all .3s cubic-bezier(.5,.02,.13,.5);
}

.breadcrumb-item a:hover {
  color: var(--primaryColor);
}

.breadcrumb-item:not(:last-child)::after {
  content: ">";
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  width: 24px;
}


</style>