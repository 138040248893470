<template>
  <Transition name="slide" mode="in-out">
      <SideBar :client="client" :locations="locations"  v-if="isSidebarOpen"/>
  </Transition>
  <HeaderComponent />
  <main>
      <router-view 
      :client="client" 
      :locations="locations"
      />
    </main>
  <FooterComponent />
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import SideBar from '@/components/HeaderSideBar.vue';
import { mapGetters, mapActions } from 'vuex';
import { fetchClient } from './api/client/main';
import { fetchLocations } from './api/location/main';



export default {
  name: 'MainApp',
  components: {
    HeaderComponent,
    FooterComponent,
    SideBar,
  },
  data() {
    return {
      client: null,
      locations: null,
    };
  },
  async mounted() {
    try {
      const data = await fetchClient();
      this.client = data;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    try {
      const data = await fetchLocations();
      this.locations = data;
      console.log(this.locations.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },

  
  computed: {
    ...mapGetters('sidebar', ['isSidebarOpen']), 
  },
  methods: {
    ...mapActions('sidebar', ['openSidebar', 'closeSidebar']), 
  }};
</script>


<style lang="scss">
@import "./assets/styles/main.scss";


.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-enter-from {
  transform: translateX(-100%);
}

.slide-enter-to {
  transform: translateX(0); 
}

.slide-leave-from {
  transform: translateX(0);
}

.slide-leave-to {
  transform: translateX(-100%);
}
</style>


