<template>
  
  <div class="page-home">
    <BannerCarousel v-if="client?.data.bg.slides?.length > 0" :slides="client?.data.bg.slides" />
    <TabBar />
    <Restoraunts :locations="locations" />
    <AboutUsComponent :description=client?.data.description />
  </div>

</template>
  
<script>
import BannerCarousel from '../components/BannerCarousel.vue';
import TabBar from '../components/TabBar.vue';
import Restoraunts from '../components/RestorauntsComponent.vue';
import AboutUsComponent from '../components/AboutUs.vue';




export default {
  name: "HomePage",
  components: {
    BannerCarousel,
    TabBar,
    Restoraunts,
    AboutUsComponent
  },
  props: ['client', 'locations']

}

</script>
  
<style lang="scss" scoped>
</style>
  

