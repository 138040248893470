<template>
    <div class="menu__wrap">
        <div class="menu__nav_wrap">
  <div class="nav-menu__item">
    <div class="nav-menu__search">
        <input
            name="search"
            type="search"
            v-model="searchQuery"
            class="nav-menu__search_input"
            placeholder="Пошук страви"
            />
      <div class="nav-menu__search_icon">
        <!-- SVG for search icon -->
      </div>
      <button class="nav-menu__delete_icon">
        <!-- SVG for delete icon -->
      </button>
    </div>
    <div class="nav-menu" v-if="menu && menu.data">
      <div
        v-for="category in menu.data"
        :key="category.id"
        :id="'category-' + category.id" 
        class="nav-menu__sections_menu"
      >
        <div v-if="category.parent === null" class="nav-menu__item">
          <button class="nav-menu__item_section" @click="toggleCategory(category.id)" :aria-expanded="activeCategoryId === category.id">
            <div class="nav-menu__item_section_label">
              <span class="nav-menu__item_section_img">
                <picture v-if="category?.image?.image_png">
                  <source :srcset="category?.image?.image_webp || '/path/to/default-image.webp'" type="image/webp" />
                  <source :srcset="category?.image?.image_png || '/path/to/default-image.png'" type="image/png" />
                  <img :src="category?.image?.image_png || '/path/to/default-image.png'" alt="Category image" />
                </picture>
              </span>
              <div class="nav-menu__item_section_text">
                {{ category.title }}
              </div>
            </div>
          </button>
        </div>
        
        <div v-if="activeCategoryId === category.id" class="nav-menu__item_children scrollable">
          <div
            v-for="child in category.children || []"
            :key="child.id"
            :class="['nav-menu__item_child', { 'active-subcategory': selectedSubcategory === child.id }]"
          >
            <button
              class="nav-menu__item_section_label"
              @click="selectSubcategory(child.id)"
              :aria-label="'Select ' + child.title"
            >
              <div class="nav-menu__item_section_text __subcategory">
                {{ child.title || 'Немає назви' }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      <div class="menu__blocks">
        <div v-for="category in menu?.data || []" :key="category.id">
          <div v-for="(subcategory, index) in category.children || []" :key="index" :id="`subcategory-${subcategory.id}`" class="menu__block">
            <div class="menu__title">
              <picture v-if="subcategory?.image?.image_png">
                <source :srcset="subcategory?.image?.image_webp " type="image/webp" />
                <source :srcset="subcategory?.image?.image_png " type="image/png" />
                <img :src="subcategory?.image?.image_png " alt="Subcategory image" />
              </picture>
              {{ subcategory?.title || 'Немає назви' }}
            </div>
            <div class="menu__grid-layout menu__grid-layout_row">
              <div v-for="product in subcategory.products || []" :key="product.id" class="menu-card">
                <div class="menu-card__main">
                  <div class="menu-card__row-main">
                    <div class="menu-card__top">
                      <div class="menu-card__image-ibg">
                        <picture>
                          <source :srcset="product?.image?.image_webp || '/path/to/default-image.webp'" type="image/webp" />
                          <source :srcset="product?.image?.image_png || '/path/to/default-image.png'" type="image/png" />
                          <img :src="product?.image?.image_png || '/path/to/default-image.png'" alt="Product image" />
                        </picture>
                      </div>
                    </div>
                    <div class="menu-card__content">
                      <div class="menu-card__title">{{ product?.title || 'Немає назви' }}</div>
                      <div class="menu-card__row">
                        <div class="menu-card__prices">
                          <span class="menu-card__new-price">{{ product?.price || 'Ціна невідома' }}</span>
                        </div>
                        <button class="menu-card__btn" aria-label="Add product to order">
                          <svg width="22" height="22" class="cursor-pointer">
                            <use xlink:href="/icons/base.svg#plus"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { fetchMenu } from '@/api/menu/main';
  
  export default {
    data() {
      return {
        menu: null,
        activeCategoryId: null,
        selectedSubcategory: null,
        searchQuery: "",
      };
    },

    async mounted() {
      try {
        const data = await fetchMenu();
        this.menu = data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    
    methods: {
        toggleCategory(id) {
            if (this.activeCategoryId === id) {
                this.activeCategoryId = null;
            } else {
                this.activeCategoryId = id;
                this.selectSubcategory(this.menu.data.find(cat => cat.id === id)?.children?.[0]?.id);
            }
        },
        selectSubcategory(id) {
            this.selectedSubcategory = id;
            this.scrollToElement(`#subcategory-${id}`);
        },
  
        scrollToElement(selector) {
            this.$nextTick(() => {
                const element = this.$el.querySelector(selector);
                if (element) {
                const position = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: position - 100, 
                    behavior: 'smooth'
                });
            }
        });
      }
    }
  };
  </script>
  

  <style scoped>
  
.menu {
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-top: 27px
}

@media (max-width: 767.98px) {
    .menu {
        padding-top:0
    }
}

.menu__wrap {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 16px;
    z-index: 3
}

@media (max-width: 767.98px) {
    .menu__wrap {
        flex-flow:column nowrap;
        margin-top: 16px
    }

    .menu__wrap.menu_mobile .menu__nav_wrap {
        min-height: 48px;
        position: fixed;
        top: 57px;
        z-index: 3;
        max-height: 80vh; /* Максимальна висота для контейнера */
        overflow-y: auto; /* Дозволяємо вертикальну прокрутку */
    }

    .menu__wrap.menu_mobile .nav-menu__sections_menu {
        flex-flow: row nowrap;
        gap: 0;
        height: auto;
        overflow-x: auto;
        overflow-y: hidden;
        position: fixed;
        width: 100vw
    }

    .menu__wrap.menu_mobile .nav-menu__item {
        min-height: 48px;
    }

    .menu__wrap.menu_mobile .nav-menu__item_category {
        border: 0;
        overflow-y: auto;
        border-radius: 0;
        box-shadow: none;
        height: 100%;
        justify-content: flex-start
    }

    .menu__wrap.menu_mobile .nav-menu__item_category_img {
        display: flex;
        height: 24px;
        margin-left: 12px;
        width: 16px;
        align-items: center;
    }

    .menu__wrap.menu_mobile .nav-menu__item_category_text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding: 0 12px
    }

    .menu__wrap.menu_mobile .nav-menu__item_category_text.active {
        font-weight: 600
    }
}

@media (max-width: 767.98px) and (max-width:767.98px) {
    .menu__wrap.menu_mobile .menu__blocks {
        margin-top:126px
    }
}

.menu__nav_wrap {
    align-items: center;
    bottom: 40px;
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
    height: 100%;
    left: 0;
    min-width: 282px;
    position: sticky;
    top: 126px;
    z-index: 2
}

@media (max-width: 767.98px) {
    .menu__nav_wrap {
        min-width:0
    }
}

.menu__bottom {
    bottom: 16px;
    left: 0;
    margin-top: 32px;
    position: sticky;
    width: 100%
}

@media (min-width: 767.98px) {
    .menu__bottom {
        display:none
    }
}

.menu__to-order {
    bottom: 35px;
    left: 16px;
    position: fixed;
    width: calc(100% - 32px);
    z-index: 4
}

@media (min-width: 767.98px) {
    .menu__to-order {
        display:none!important
    }
}

.menu__bottom .button-app {
    bottom: 16px;
    width: 100%
}

@media (max-width: 767.98px) {
    .menu__container {
        background-color:var(--body-color);
        border-radius: 20px 20px 0 0;
        margin-top: -20px;
        position: relative;
        z-index: 2
    }
}

.menu__main-img {
    display: inline-flex;
    height: 220px;
    position: relative;
    width: 100%
}

.menu__main-img picture {
    height: 100%;
    width: 100%
}

.menu__main-img picture img {
    width: 100%
}

@media (min-width: 767.98px) {
    .menu__main-img {
        display:none
    }
}

.menu__blocks {
    display: flex;
    flex: 1 1 76%;
    flex-flow: column nowrap;
    margin-left: 24px;
    position: relative;
    z-index: 2
}

@media (max-width: 767.98px) {
    .menu__blocks {
        margin-left:0;
        margin-top: 92px
    }
}

.menu__block:not(:last-child) {
    margin-bottom: 44px
}

@media (max-width: 767.98px) {
    .menu__block:not(:last-child) {
        margin-bottom:24px
    }
}

.menu__work-time {
    margin-bottom: 10px
}

@media (max-width: 767.98px) {
    .menu__work-time {
        align-self:center;
        margin-top: 25px
    }
}

.menu__work-time p {
    background: var(--color-pink-nude,#ffe0e0);
    border-radius: 12px;
    display: inline-flex;
    font-weight: 600;
    gap: 12px;
    line-height: 24px;
    padding: 12px 24px
}

@media (max-width: 767.98px) {
    .menu__work-time p {
        font-size:12px;
        line-height: 18px;
        padding: 12px
    }
}

.menu__work-time p img {
    flex-shrink: 0;
    height: 24px;
    width: 24px
}

.menu__grid-layout {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill,minmax(250px,1fr))
}

@media (max-width: 767.98px) {
    .menu__grid-layout {
        gap:16px 8px;
        grid-template-columns: repeat(2,1fr)
    }

    .menu__grid-layout_row {
        gap: 0;
        grid-template-columns: repeat(1,1fr)
    }
}

.nav-menu {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    white-space: nowrap;
    width: 100%;
}

@media (max-width: 767.98px) {
    .nav-menu {
        position:relative;
        top: 0
    }
}

.nav-menu__sections_menu {
    background-color: var(--body-color);
    display: flex;
    flex-flow: column nowrap;

    overflow-x: hidden;
    overflow-y: auto
}

.nav-menu__sections_menu::-webkit-scrollbar {
    display: none
}

@media (max-width: 767.98px) {
    .nav-menu__sections_menu {
        gap:16px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto
    }

    .nav-menu__sections_menu::-webkit-scrollbar {
        display: none
    }
}

.nav-menu__sections_menu_modal {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 56px
}

.nav-menu__sections_menu_modal::-webkit-scrollbar {
    display: none
}

.nav-menu__search {
    align-items: center;
    display: flex;
    height: 48px;
    position: relative;
    width: 100%
}

.nav-menu__search_input {
    border: 1px solid var(--primaryColor);
    background-color: var(--background-color);
    border-radius: 20px;
    height: 100%;
    padding-left: 54px;
    padding-right: 40px;
    width: 100%
}

.nav-menu__search_input:focus {
  border: 1px solid var(--secondaryColor);
  outline: none;
}
.nav-menu__search_input::-moz-placeholder {
    color: solid var(--text-color);
    font-size: 14px;
    line-height: 20px
}

.nav-menu__search_input::placeholder,.nav-menu__search_message {
    color: var(--text-color);
    font-size: 14px;
    line-height: 20px
}

.nav-menu__search_message {
    display: none;
    margin-top: 5px
}

.nav-menu__search_message.show {
    display: block
}

.nav-menu__search_icon {
    height: 24px;
    left: 22px;
    width: 24px
}

.nav-menu__delete_icon,.nav-menu__search_icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.nav-menu__delete_icon {
    display: none;  
    opacity: .3;
    background: none;

}

.nav-menu__delete_icon.show {
    display: block
}

.nav-menu__item {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex-flow: column nowrap;
    min-height: 48px;
    gap: 24px;
    width: 100%;
    z-index: 3
}

@media (max-width: 767.98px) {
    .nav-menu__item {
        min-height:64px
    }
}

.nav-menu__item_section {
    align-items: center;
    display: inline-flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    padding: 0 8px;
    text-align: left;
    transition: all .3s cubic-bezier(.5,.02,.13,.5);
    width: 100%
}

.nav-menu__item_section_label {
    display: inline-flex
}

@media (max-width: 767.98px) {
    .nav-menu__item_section_label {
        padding-left:24px
    }
}

.nav-menu__item_section_img {
    display: flex;
    height: 24px;
    margin-left: 8px;
    width: 16px;
    align-items: center;
}

.nav-menu__item_section_text {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-left: 8px;
    text-transform: capitalize
}

@media (max-width: 767.98px) {
    .nav-menu__item_section_text {
        margin-left:0
    }
}

.nav-menu__item_section_text:first-letter {
    text-transform: uppercase
}

.nav-menu__item_section_text.active {
    font-weight: 700
}

@media (max-width: 767.98px) {
    .nav-menu__item_section {
        border:1px solid #e8e8e8;
        border-radius: 20px;
        box-shadow: 0 4px 4px 0 rgba(0,0,0,.05);
        justify-content: space-between;
        padding: 0;
        width: 100%
    }
}

.nav-menu__item_submenu {
    padding: 0 16px;
    z-index: 3
}

.nav-menu__item_category {
    text-transform: capitalize;
    width: 100%
}

.nav-menu__item_category.active {
    border: 1px solid #ec4700;
    border-radius: 20px;
    padding: 1px
}

.nav-menu__item_category_label {
    align-items: center;
    display: inline-flex;
    flex-flow: row nowrap;
    height: 100%;
    width: 100%
}

.nav-menu__item_category_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 12px 24px;
    text-align: left
}

@media (max-width: 767.98px) {
    .nav-menu__item_arrow_right {
        display:inline-flex;
        padding-right: 12px
    }
}

.nav-menu__item img {
    border-radius: 4px;
    max-width: 20px;
    overflow: hidden
}
.menu__title:not(:last-child) {
    margin-bottom: 24px;
}

.menu__title {
    align-items: center;
    display: flex;
    font-size: 32px;
    font-weight: 600;
    gap: 8px;
    line-height: 32px;
    margin-left: 16px;
    text-transform: capitalize
}
.menu__title img {
    border-radius: 6px;
    flex-shrink: 0;
    height: 32px;
    overflow: hidden;
    width: 32px;
}

.menu__grid-layout {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}



.active-subcategory {
    border: 2px solid var(--primaryColor);
    border-radius: 20px;
}
.__subcategory{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 12px 24px;
    text-align: left;
    padding: 0;
}
@media (max-width: 767.98px) {
    .nav-menu {
        position:relative;
        top: 0
    }
}
@media (max-width: 767.98px) {
    .nav-menu__item_review_wrapper {
        margin:0 12px 0 24px
    }

}
@media (max-width: 767.98px) {
    .nav-menu__item_review_text {
        margin-left:0
    }
}
@media (max-width: 767.98px) {
    .menu-info__row.menu_mobile {
        background-color:var(--body-color);
        margin: 0 -15px;
        min-height: 44px;
        position: fixed;
        top: 104px;
        width: 100%;
        z-index: 3
    }
}

.menu-card {
    background-color: var(--white);
    border-radius: 16px;
    cursor: pointer;
    padding: 16px 12px;
    transition: transform .2s linear
}

.menu-card:active {
    transform: scale(.95)
}

@media (max-width: 767.98px) {
    .menu-card {
        padding:0 20px 2px 16px
    }
}

@media (hover: hover) {
    .menu-card:hover {
        background-color:var(--white);
        box-shadow: 0 14px 30px 0 hsla(0,0%,60%,.2)
    }
}

.menu-card_list {
    padding: 16px 14px 16px 0;
    position: relative
}

@media (max-width: 767.98px) {
    .menu-card_list:last-child:after {
        display:none
    }
}

.menu-card_list:after {
    background: #f0f0f0;
    bottom: 0;
    content: "";
    height: 1px;
    left: -20px;
    position: absolute;
    width: 140%
}

.menu-card_list .menu-card__content {
    flex: 1 1 auto;
    width: 100%
}

.menu-card_list .menu-card__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 4px
}

.menu-card_list .menu-card__row-main {
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    gap: 12px
}

.menu-card_list .menu-card__row-main:not(:last-child) {
    margin-bottom: 12px
}

.menu-card__description {
    font-size: 12px;
    line-height: 16px;
    max-height: 34px;
    overflow: hidden;
    text-overflow: ellipsis
}

@media (max-width: 767.98px) {
    .menu-card_list .tags {
        max-width:calc(100% - 20px)
    }
}

.menu-card_list .menu-card__top {
    flex-shrink: 0;
    padding-bottom: 27.9069767442%;
    width: 34.8837209302%
}

@media (max-width: 767.98px) {
    .menu-card_list .menu-card__top {
        padding-bottom:30.8139534884%;
        width: 43.6046511628%
    }
}

.menu-card__top {
    border-radius: 32px;
    height: 240px;
    position: relative;
    width: 100%
}

@media (max-width: 767.98px) {
    .menu-card__top {
        height:auto;
        padding-bottom: 93.5251798561%
    }

    .menu-card__top .menu-card__basket {
        align-items: center;
        background: rgba(0,0,0,.55);
        border-radius: 32px;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%
    }

    .menu-card__top .menu-card__basket .menu-card__basket_count {
        color: var(--white);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-align: center
    }
}

.menu-card__image-ibg {
    border-radius: 32px;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%
}

@media (max-width: 767.98px) {
    .menu-card__image-ibg {
        border-radius:10px
    }
}

.menu-card__image-ibg img,.menu-card__image-ibg picture {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    width: 100%
}

.menu-card__image-ibg img {
    left: 0;
    top: 0
}

.menu-card__content {
    padding-top: 8px
}

@media (max-width: 767.98px) {
    .menu-card__content {
        position:relative
    }
}

.menu-card__title {
    color: #000;
    font-weight: 600;
    line-height: 24px;
    word-break: break-word
}

@media (max-width: 767.98px) {
    .menu-card__title {
        font-size:14px;
        font-weight: 400;
        line-height: 1.4285714286
    }
}

.menu-card__title:not(:last-child) {
    margin-bottom: 4px
}

@media (max-width: 767.98px) {
    .menu-card__title:not(:last-child) {
        margin-bottom:0
    }
}

.menu-card__row {
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: space-between
}

.menu-card__new-price {
    color: var(--orange);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px
}

@media (max-width: 767.98px) {
    .menu-card__new-price {
        font-size:16px
    }

    .menu-card_list .menu-card__btn {
        bottom: -18px;
        right: -18px;
        top: auto;
        transform: translateX(0)
    }
}

.menu-card__btn {
    align-items: center;
    background: #f0f0f0;
    border: 1px solid #f0f0f0;
    border-radius: 36px;
    display: inline-flex;
    flex-shrink: 0;
    height: 36px;
    justify-content: center;
    transition: all .3s cubic-bezier(.5,.02,.13,.5);
    width: 36px
}

@media (max-width: 767.98px) {
    .menu-card__btn {
        box-shadow:0 0 0 3px #fff;
        position: absolute;
        right: -20px;
        top: 0;
        transform: translateY(-25px)
    }
}

@media (hover: hover) {
    .menu-card__btn:hover {
        background:#fff;
        border-color: var(--orange)
    }
}

.menu-card-mob__item {
    border-left: 4px solid var(--text-primary,#ec4700);
    margin-left: -16px;
    margin-right: -32px;
    padding: 8px 18px;
    position: relative
}

.menu-card-mob__item:first-child {
    margin-top: 16px
}

.menu-card-mob__item:not(:last-child):before {
    background: var(--orange);
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: calc(100% + 16px);
    z-index: 2
}

.menu-card-mob__item:after {
    background: var(--color-gray-90,#f0f0f0);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% + 32px)
}

.menu-card-mob__row {
    align-items: center;
    display: flex;
    gap: 14px;
    min-height: 50px;
    position: relative;
    width: 100%;
    z-index: 2
}

.menu-card-mob__button {
    background-color: var(--color-gray-90);
    flex-shrink: 0;
    height: 20px;
    width: 20px
}

.menu-card-mob__main {
    flex: 1 1 auto
}

.menu-card-mob__head {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    gap: 8px;
    justify-content: space-between;
    line-height: 18px
}

.menu-card-mob__head:not(:last-child) {
    margin-bottom: 8px
}

.menu-card-mob__descr {
    font-size: 12px;
    line-height: 16px
}

.button-app {
    align-items: center;
    background: var(--orange);
    border-radius: 24px;
    display: inline-flex;
    font-size: 14px;
    font-weight: 700;
    gap: 18px;
    height: 48px;
    justify-content: center;
    padding: 5px 12px;
    transition: all .3s cubic-bezier(.5,.02,.13,.5)
}

@media (hover: hover) {
    .button-app:hover {
        background:#c93f01
    }
}

.button-app:active {
    background: #b43901
}

.button-app_bold {
    font-weight: 700
}

.button-app_border {
    background: #ffe0e0;
    border: 1px solid #ff1313;
    box-shadow: 0 6px 20px 0 hsla(14,8%,59%,.16)
}

@media (hover: hover) {
    .button-app_border:hover {
        background:#ffc9c9
    }
}

.button-app_border-white {
    background: transparent;
    border: 1px solid #ec4700;
    border-radius: 24px;
    box-shadow: 0 6px 20px 0 hsla(14,8%,59%,.16);
    color: var(--orange)
}

@media (hover: hover) {
    .button-app_border-white:hover {
        background:#ffc9c9
    }
}

.button-app_not-verify {
    background: #afafaf;
    box-shadow: 0 6px 20px 0 hsla(14,8%,59%,.16);
    pointer-events: none
}

.button-app:disabled {
    opacity: .6;
    pointer-events: none
}

.modal-card {
    border-radius: 24px;
    max-height: 100%;
    overflow: hidden;
    position: relative
}

@media (max-width: 767.98px) {
    .modal-card {
        height:85vh
    }
}

.modal-card__scroll {
    max-height: 100%;
    overflow-y: auto
}

@media (min-width: 767.98px) {
    .modal_product .modal-card__scroll {
        display:flex
    }
}

.modal-card__scroll::-webkit-scrollbar {
    display: none
}

.modal-card__close {
    position: relative!important
}

.modal-card__close svg {
    fill: currentColor;
    height: 24px;
    width: 24px
}

.modal-card__button {
    align-items: center;
    background: hsla(0,0%,100%,.85);
    border-radius: 32px;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    display: inline-flex;
    height: 44px;
    justify-content: center;
    position: absolute;
    transition: all .3s cubic-bezier(.5,.02,.13,.5);
    width: 44px;
    z-index: 2
}

@media (hover: hover) {
    .modal-card__button:hover {
        box-shadow:0 0 15px var(--orange)
    }
}

.modal-card__top {
    position: relative;
    width: 100%
}

.modal-card__like {
    bottom: 32px;
    color: var(--orange);
    right: 16px
}

.modal-card__like svg {
    fill: currentColor
}

.modal-card__image-ibg {
    border-radius: 16px 16px 0 0;
    height: 290px;
    overflow: hidden;
    position: relative
}

.modal-card__image-ibg img {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%
}

.modal-card__content {
    -ms-overflow-style: none;
    background: #fff;
    border-radius: 20px 20px 16px 16px;
    display: flex;
    flex-direction: column;
    margin-top: -24px;
    min-height: 506px;
    padding: 24px 16px 0;
    position: relative;
    scrollbar-width: none;
    z-index: 2
}

@media (max-width: 767.98px) {
    .modal-card__content {
        min-height:300px
    }
}

.modal-card__info {
    flex: 0 1 auto
}

.modal-card__title {
    font-weight: 700;
    margin-bottom: 9px
}

.modal-card__prices,.modal-card__title {
    font-size: 20px;
    line-height: 26px
}

.modal-card__prices:not(:last-child) {
    margin-bottom: 8px
}

.modal-card__new-price {
    color: var(--orange);
    font-weight: 700
}

.modal-card__old-price {
    font-size: 20px;
    line-height: 24px
}

.modal-card__description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px
}

.modal-card__bottom {
    align-items: center;
    background: var(--white);
    bottom: 0;
    display: flex;
    gap: 12px;
    left: 0;
    padding: 16px 0 24px;
    position: sticky;
    transition: all .3s cubic-bezier(.5,.02,.13,.5)
}

.modal-card__bottom .button-app {
    width: 100%
}

.modal-card__head {
    margin-top: 32px
}

.head-modal:not(:last-child) {
    margin-bottom: 20px
}

@media (max-width: 767.98px) {
    .head-modal:not(:last-child) {
        margin-bottom:8px
    }
}
</style>
  