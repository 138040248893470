<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="content">
        <div class="info">
          <div class="info-data">
            <div class="title">
              Контакти
            </div>
            <div class="info-content">
              <div class="info-field">
                <img src="@/assets/icons/footer_icon/Location.svg" class="_desktop" alt="location">
                Київ, вул. Вадима Гетьмана 6, ТРЦ "Cosmo Multimall"
              </div>
              <div class="info-field">
                <img src="@/assets/icons/footer_icon/Phone, Call.svg" class="_desktop" alt="phone_call">
                066-067-01-58
              </div>
            </div>
          </div>
          <div class="info-data">
            <div class="title no-margin-bottom">
              Робочий час
            </div>
            <div class="info-content">
              <div class="info-field">
                11:30 - 23:00
              </div>
            </div>
          </div>
        </div>

        <div class="social">
          <div class="title _desktop">
              Соцмережі
          </div>
          <div class="social-network">
            <div class="social-icon">
              <img src="@/assets/icons/footer_icon/Facebook.svg" alt="facebook">
            </div>
            <div class="social-title _desktop">Facebook</div>
          </div>

          <div class="social-network">
            <div class="social-icon">
              <img src="@/assets/icons/footer_icon/Instagram.svg" alt="instagram">
            </div>
            <div class="social-title _desktop">Instagram</div>
          </div>

          <div class="social-network">
            <div class="social-icon">
              <img src="@/assets/icons/footer_icon/Youtube.svg" alt="youtube">
            </div>
            <div class="social-title _desktop">Youtube</div>
          </div>

          <div class="social-network">
            <div class="social-icon">
              <img src="@/assets/icons/footer_icon/TikTok.svg" alt="tiktok">
            </div>
            <div class="social-title _desktop">TikTok</div>
          </div>
        </div>
        <div class="map _desktop">
          <div class="title">
              Розташування
            </div>
            <span>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2542.1489237332044!2d30.477157300000005!3d50.419696900000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cecfa0752ca3%3A0x66201d095fad218d!2z0LLRg9C70LjRhtGPINCT0YDQuNCz0L7RgNGW0Y8g0JrQvtGH0YPRgNCwLCAxNtCQLCDQmtC40ZfQsiwgMDIwMDA!5e0!3m2!1suk!2sua!4v1730740418333!5m2!1suk!2sua" width="250" height="150" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style="border-radius: 16px; border: none;" ></iframe></span>
        </div>
      </div>
      <div class="copyright">
        <div class="copyrught-img">
          <img src="@/assets/icons/Group.png" alt="group">
        </div>

        <p>2023 Mimosa restoraunt</p>
      </div>
      <div class="footer-logo">
        <img src="@/assets/icons/Logo_GO.svg" alt="logo">
      </div>
    </div>
  </footer>
</template>
  
<script>
export default {
  name: "FooterComponent",
};
</script>
  
<style lang="scss" scoped>
.footer {
  background-color: var(--footer-back);
  color: #fff;
  display: flex;
  justify-content: center;
}
.footer-container { 
  width: 1200px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0 32px 0;
}
@media (max-width: 1264px) {
  .footer-container {
    width: calc(100% - 64px);
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 131px;
  flex: none;
  margin: 16px 0px;
}
@media (max-width: 914px) {
  .content {
    gap: 90px;
  }
}
@media (max-width: 830px) {
  .content {
    gap: 59px;
  }
}


.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  padding: 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
  font-weight: 500;
}
@media (max-width: 769px) {
  .footer-container {
    justify-content: center;
    padding: 24px, 16px, 16px, 16px;
  }
  .content {
    flex-direction: column;
    margin: 0;
    gap: 32px;
  }
  .title {
    margin: 0;
  }
  .title.no-margin-bottom  {
    margin-top: 11px !important;
  }
  .social {
    display: flex;
    gap: 28px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
.title.no-margin-bottom {
  margin-bottom: 0; 
  margin-top: 24px;
}

.info-content { 
  display: flex;
  flex-direction: column;
}

.info-field {
  display: flex;
  gap: 12px;
  max-width: 228px;
  text-wrap: wrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 6px 0;
}

.social-network {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
.social-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  
}
.copyright {
  display: flex;
  gap: 8px;
  align-items: center;
}
.copyrught-img {
  width: 24px;
  height: 24px;
}

@media (max-width: 769px){
.footer-logo {
  width: 100%;
  display: flex;
  justify-content: center;
}
.copyrught-img {
  font-size: 12px;
  line-height: 16px;
}
}

</style>
