import axios from 'axios';

const API_URL = 'https://api.getorder.biz/api/v2/pwa/getorder';
const AUTH_TOKEN = 'T1RreVl6QXdOMk5oTm1SaE56ZGlOQT09OnBtVFkxaDBDWVdnTEVDTmpVS0htS3c9PQ=='; 

export async function fetchClient() {
  try {
    const response = await axios.get(API_URL, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `${AUTH_TOKEN}`,
      },
    });
    localStorage.setItem('theme', response.data.data.pwa_settings.color_scheme);
    document.documentElement.style.setProperty('--primaryColor', response.data.data.pwa_settings.primary_color);
    document.documentElement.style.setProperty('--secondaryColor', response.data.data.pwa_settings.secondary_color);
    
    return response.data;
   
  } catch (error) {
    console.error('Error fetching locations:', error);
    throw error;
  }
}

