<template>
    <div v-if="isSidebarOpen" class="overlay" @click="closeSidebar">
        <div class="sidebar" @click.stop>
          <div class="sidebar-contents">
            <div class="header-block">
        
              <div class="sidebar-logo">
                <a :href="'/'">
                  <picture v-memo="client.data.logo.webp">
                    <source :srcset="client.data.logo.webp" type="image/webp">
                    <source :srcset="client.data.logo.png" type="image/png">
                    <img :src="client.data.logo.webp" alt="Logo">
                  </picture>
                </a>
              </div>
              <div class="text-block">
                <a :href="'/'">
                  {{ client.data.organization }}
                </a>
              </div>
              <div class="header-close-btn" @click="closeSidebar">
                <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g clip-path="url(#clip26_4767)">
                    <rect id="state-layer" rx="0.000000" width="39.000000" height="39.000000" transform="translate(-7.500000 -7.500000)" fill-opacity="0"/>
                    <path id="icon" d="M19 6.41L17.58 5L12 10.58L6.41 5L5 6.41L10.58 12L5 17.58L6.41 19L12 13.41L17.58 19L19 17.58L13.41 12L19 6.41Z" fill-opacity="1.000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </div>
            </div>
            <div class="address-block">
              <div class="address-text">
                Моя адреса
              </div>
              <div class="address-btn complect">
                <button>
                <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <path id="Path" d="M18 13L18 19C18 20.1 17.1 21 16 21L5 21C3.89 21 3 20.1 3 19L3 8C3 6.89 3.89 6 5 6L9 6"  stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
                  <path id="Path" d="M11.14 14.61C11.37 14.58 11.57 14.48 11.73 14.32L19.36 6.69C20.21 5.84 20.21 4.47 19.36 3.63C18.51 2.78 17.15 2.78 16.3 3.63L8.74 11.19C8.59 11.34 8.49 11.54 8.46 11.75L8 14.99L11.14 14.61Z"  stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round"/>
                </svg>
              </button>
              </div>
            </div>
            <div class="tabs-title">
              Контакти
            </div>
            <div v-for="location in locations?.data" :key="location.id" class="accordion">
              <div v-if="slug">

                <div v-if="location.uniq_id === slug">
                  <SideBarData :location="location"/>
                </div>
              </div>
              <div v-else>
                <button
                  @click="toggleAccordion(location.id)"
                  :class="['accordion-button', { 'accordion-active': activeLocation === location.id }]"
                >
                  {{ location.title }}
                </button>
                <div :class="['accordion-content', { active: activeLocation === location.id }]">
                  <SideBarData :location="location"/>
                </div>
              </div>
            </div>

              
            <div class="sidebar-tabs">
            <div class="tabs-title">
              <a :href="privacy">Політика конфіденційності</a>
            </div>
            <div class="tabs-title">
              <a :href="conditions">Умови використання</a>
            </div>
            <div class="tabs-title">
              <a :href="about_us">Інформація про компанію</a>
            </div>
        </div>        
    </div>
  </div>
</div>
    
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SideBarData from '@/components/HeaderSideBarData.vue';

export default {
  components: {
    SideBarData
  },
  data() {
    return {
      locationData: [],
      activeLocation: null,
    };
  },
  computed: {
    ...mapGetters('sidebar', ['isSidebarOpen']),
  },
  methods: {
    ...mapActions('sidebar', ['closeSidebar']),
    toggleAccordion(locationId) {
      this.activeLocation = this.activeLocation === locationId ? null : locationId;
    }
  },
  async created() {
    try {
      const slug = this.$route.params.slug;  
      this.slug = slug;  
    } catch (error) {
      console.error("Error fetching product by slug:", error);
    }
  },

  props: ['client', 'locations']
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1000;
}
.overlay a {
  text-decoration: none;
}
.overlay a:hover {
  color: var(--primaryColor);
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}

.sidebar {
  width: 400px;
  height: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
  position: relative;
  z-index: 1001;
  padding: 20px;
  color: var(--color-tabs-text);
  overflow-y: auto;
  will-change: transform;
}
.sidebar-contents {
  padding: 24px 12px 32px  0 ;
}
.header-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-block {
  max-width: 184px;
}

.sidebar-logo svg {
  width: 56px;
  height: 56px;
}
.text-block {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.header-close-btn {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-close-btn svg:hover {
  fill: var(--primaryColor);
}

.header-close-btn img {
  width: 24p;
  height: 24px;
}

.address-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 16px 0;
}
.address-text { 
  font-size: 16px;
  line-height: 25.6px;
}
.address-btn { 
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.address-btn img { 
  width: 24px;
  height: 24px;
}
.sidebar-tab { 
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tab-ico,
.social-ico {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width:28px;
  height: 28px;
}

.tabs-title {
  max-width: 230px;
}
.tab-title { 
  max-width: 230px;
  font-size: 14px;
  line-height: 20px;
}
.header-close-btn:hover {
  cursor: pointer;
}
.accordion button {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  position: relative;
}

.accordion-button.accordion-active {
  border: 2px solid var(--primaryColor);
}
.accordion button::after {
  content: '>'; 
  font-size: 16px;
  color: var(--text-color);
  position: absolute;
  right: 20px;
  transition: transform 0.2s ease;
}


.accordion button.active::after {
  transform: rotate(90deg);
}

.accordion button:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}


.accordion-content {
  padding: 5px;
  background-color: var(--content-background-color);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.accordion-content.active {
  max-height: 1000px;
  transition: max-height 0.5s ease-in; 
}
.sidebar-tabs {
    display: flex;
    gap: 10px;
    margin-top: 34px;
    flex-direction: column;
    font-size: 16px;
    cursor: pointer;
    color: var(--text-color);
}
.tabs-title {
  max-width: 230px;
  font-size: 16px;
  font-weight: 500;
}
</style>
