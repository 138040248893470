import axios from 'axios';

const API_URL = 'https://pwa.ordr.bz/api/v1/pwa/get_menu/';

export async function fetchMenu() {
  try {
    const response = await axios.get(API_URL, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    
    console.log(response.data);
    return response.data;

  } catch (error) {
    if (error.response) {
      console.error('Server Error:', error.response.status, error.response.data);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Request Error:', error.message);
    }
    throw error;
  }
}
