<template>
<div class="about_us">
    <div class="about-us__container">
        
        <span class="description" v-html="description"></span>
    
    </div>
</div>

</template>
    
<script>

export default {
    props: ['description']
};
</script>
    
<style scoped> 
.about_us {
  padding: 48px 0;
}
.description h2{
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    margin-bottom: 4px;
}
</style>
    