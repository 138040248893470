<template>
  <header class="header">
    <nav>
      <div class="header__container">
        <div class="go_header">
          <div class="header__row no_border_bottom">
            <div class="menu">
              <div class="menu-burger"  @click="toggleSidebar">
                <span
                  class="menu-burger-line"
                ></span>
                <span
                  class="menu-burger-line"
                ></span>
                <span
                  class="menu-burger-line"
                ></span>
              </div>
            </div>
            <div class="header-buttons">
              <div class="button _desktop">
                <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g clip-path="url(#clip13_7091)">
                    <path id="Vector" d="M10.25 2C11.81 2 13.35 2.44 14.67 3.28C16 4.12 17.05 5.32 17.71 6.74C18.38 8.16 18.63 9.74 18.43 11.3C18.23 12.85 17.59 14.32 16.58 15.52L22.27 21.22C22.37 21.31 22.44 21.42 22.48 21.55C22.52 21.68 22.52 21.82 22.49 21.95C22.46 22.08 22.39 22.2 22.29 22.29C22.2 22.39 22.08 22.46 21.95 22.49C21.82 22.52 21.68 22.52 21.55 22.48C21.42 22.45 21.31 22.37 21.21 22.27L15.52 16.58C14.51 17.43 13.3 18.02 12 18.31C10.71 18.59 9.37 18.55 8.09 18.21C6.81 17.86 5.63 17.21 4.66 16.31C3.68 15.42 2.94 14.3 2.49 13.05C2.04 11.81 1.89 10.47 2.07 9.16C2.24 7.84 2.73 6.59 3.49 5.51C4.25 4.42 5.26 3.54 6.44 2.93C7.61 2.31 8.92 2 10.25 2ZM3.5 10.25C3.5 11.13 3.67 12.01 4.01 12.83C4.35 13.65 4.84 14.39 5.47 15.02C6.1 15.64 6.84 16.14 7.66 16.48C8.48 16.82 9.36 17 10.25 17C11.13 17 12.01 16.82 12.83 16.48C13.65 16.14 14.39 15.64 15.02 15.02C15.64 14.39 16.14 13.65 16.48 12.83C16.82 12.01 17 11.13 17 10.25C17 8.45 16.28 6.74 15.02 5.47C13.75 4.21 12.03 3.5 10.25 3.5C8.45 3.5 6.74 4.21 5.47 5.47C4.21 6.74 3.5 8.45 3.5 10.25Z"/>
                  </g>
                </svg>
              </div>
              <div class="button">
                <svg width="17.992188" height="19.016296" viewBox="0 0 17.9922 19.0163" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <path id="Vector" d="M8.99 0C10.17 -0.01 11.31 0.37 12.26 1.07C13.21 1.77 13.9 2.75 14.25 3.88C14.6 5 14.57 6.2 14.18 7.31C13.8 8.42 13.06 9.38 12.09 10.04C13.78 10.66 15.24 11.77 16.29 13.23C17.34 14.69 17.93 16.43 17.99 18.22C17.99 18.32 17.98 18.42 17.94 18.52C17.91 18.61 17.86 18.7 17.79 18.77C17.72 18.84 17.64 18.9 17.55 18.94C17.46 18.99 17.36 19.01 17.26 19.01C17.16 19.01 17.06 19 16.97 18.96C16.87 18.92 16.79 18.87 16.72 18.8C16.65 18.73 16.59 18.65 16.55 18.56C16.51 18.47 16.49 18.37 16.49 18.27C16.43 16.32 15.61 14.47 14.21 13.11C12.82 11.76 10.94 11 8.99 11C7.04 11 5.17 11.76 3.77 13.11C2.37 14.47 1.55 16.32 1.5 18.27C1.49 18.47 1.41 18.65 1.26 18.79C1.12 18.93 0.92 19 0.72 18.99C0.52 18.99 0.33 18.9 0.2 18.76C0.06 18.61 -0.01 18.42 0 18.22C0.05 16.43 0.64 14.69 1.69 13.23C2.75 11.77 4.21 10.66 5.89 10.04C4.92 9.38 4.19 8.42 3.8 7.31C3.41 6.2 3.39 5 3.73 3.88C4.08 2.75 4.78 1.77 5.73 1.07C6.67 0.37 7.82 -0.01 8.99 0ZM4.99 5.5C4.99 6.56 5.41 7.57 6.16 8.32C6.91 9.07 7.93 9.5 8.99 9.5C10.05 9.5 11.07 9.07 11.82 8.32C12.57 7.57 12.99 6.56 12.99 5.5C12.99 4.43 12.57 3.42 11.82 2.67C11.07 1.92 10.05 1.5 8.99 1.5C7.93 1.5 6.91 1.92 6.16 2.67C5.41 3.42 4.99 4.43 4.99 5.5Z" fill-opacity="1.000000" fill-rule="evenodd"/>
                  <path id="Vector" d="" fill="#323232" fill-opacity="1.000000" fill-rule="evenodd"/>
                </svg>

              </div>
              <div class="button">
                <span>UA</span>
              </div>
              <div class="button _desktop">
                <svg width="24.000000" height="24.000000" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g clip-path="url(#clip13_4700)">
                    <path id="Vector" d="M7.15 21.55C6.66 21.55 6.25 21.38 5.91 21.04C5.57 20.7 5.4 20.29 5.4 19.8C5.4 19.32 5.57 18.9 5.91 18.56C6.25 18.22 6.66 18.05 7.15 18.05C7.64 18.05 8.05 18.22 8.39 18.56C8.73 18.9 8.9 19.32 8.9 19.8C8.9 20.29 8.73 20.7 8.39 21.04C8.05 21.38 7.64 21.55 7.15 21.55ZM16.84 21.55C16.35 21.55 15.94 21.38 15.6 21.04C15.26 20.7 15.09 20.29 15.09 19.8C15.09 19.32 15.26 18.9 15.6 18.56C15.94 18.22 16.35 18.05 16.84 18.05C17.33 18.05 17.74 18.22 18.08 18.56C18.42 18.9 18.59 19.32 18.59 19.8C18.59 20.29 18.42 20.7 18.08 21.04C17.74 21.38 17.33 21.55 16.84 21.55ZM6.01 5.75L8.55 11.05L15.36 11.05C15.42 11.05 15.47 11.04 15.51 11.01C15.56 10.98 15.6 10.94 15.63 10.89L18.32 6.01C18.35 5.94 18.35 5.88 18.32 5.83C18.29 5.77 18.24 5.75 18.16 5.75L6.01 5.75ZM5.29 4.25L19.17 4.25C19.58 4.25 19.89 4.42 20.1 4.77C20.31 5.12 20.32 5.47 20.13 5.83L16.93 11.64C16.76 11.93 16.55 12.15 16.28 12.31C16.01 12.47 15.71 12.55 15.39 12.55L8.1 12.55L6.94 14.67C6.89 14.75 6.89 14.83 6.93 14.92C6.98 15.01 7.05 15.05 7.15 15.05L18.59 15.05L18.59 16.55L7.15 16.55C6.48 16.55 5.98 16.27 5.65 15.69C5.31 15.12 5.3 14.54 5.61 13.97L7.04 11.4L3.4 3.75L1.5 3.75L1.5 2.25L4.34 2.25L5.29 4.25L5.29 4.25Z" fill-opacity="1.000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header__mobile">

      </div>
    </nav>
  </header>
  
</template>

<script>
import { mapActions } from 'vuex';



export default {
  name: "HeaderComponent",
  methods: {
    ...mapActions('sidebar', ['toggleSidebar']),
    
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: var(--background-color);
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 4;
}

.header__row {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    position: relative;
  }

.menu {
  display: flex;
  align-items: center;
}

.menu-burger {
  width: 24px;
  height: 24px;
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
}

.menu-burger-line {
  border: 1.5px solid var(--text-color);

  &:nth-child(1) {
    width: 10px;
    height: 1px;
  }

  &:nth-child(2) {
    width: 14px;
    height: 1px;
  }

  &:nth-child(3) {
    width: 18px;
    height: 1px;
  }
}
@media (max-width: 769px) {
  .menu-burger-line {
    &:nth-child(1),
    &:nth-child(2) {
      width: 18px;
    }

  }
}

.header-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0px;
  color: var(--text-color);
  .button {
    width: 44px;
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: center;
    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}

.theme-toggle {
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 16px;
  padding: 8px;
}

</style>
