<template>
  <div  v-for="location in locations?.data" :key="location.id" > 
    <div v-if="location.uniq_id === market" class="page-home">
        <BreadcrumbsComponent
        :breadcrumbs="[
          { name: client.data.organization, path: '/' },
          { name: location.title, path: 'market/${{ location.uniq_id }}' },
        ]"
      />
        <BannerCarousel v-if="location?.slides?.length > 0" :slides="location?.slides" />
        <MenuComponent :location="location"/>
        <AboutUsComponent :description=location.params.description />

    </div>
  </div>
</template>
    
<script>
import BannerCarousel from '@/components/BannerCarousel.vue';
import BreadcrumbsComponent from '@/components/BreadcrumbsComponent.vue';
import MenuComponent from '@/components/MenuComponent.vue';
import AboutUsComponent from '@/components/AboutUs.vue';

  
export default {
  components: {
    BreadcrumbsComponent,
    BannerCarousel,
    MenuComponent,
    AboutUsComponent
    
  },
    computed: {
      market() {
        return this.$route.params.slug;
      }
    },
    props: ['locations', 'client']
  }
  
  </script>
    
  <style lang="scss" scoped>
  </style>
    
  
  