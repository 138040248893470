<template>
<div class="restaurants">
        <div class="restaurants__container">
            <div class="restaurants__wrap">
                <div class="restaurants__grid-layout">
                  <RestaurantCard
                    v-for="restaurant in locations?.data"
                    :key="restaurant.id"
                    :restaurant="restaurant"
                    />
                </div>
            </div>
        </div>
</div>


</template>

<script>
import RestaurantCard from '@/components/RestaurantCard.vue';

export default {
    components: {RestaurantCard},
    props: {
        locations: {
            type: Array,
            required: true
        }
    }
};
</script>

<style>
.restaurants {
    padding-bottom: 48px;
    padding-top: 30px;
}

.restaurants__grid-layout {
    display: grid;
    gap: 40px 4px;
    grid-template-columns: repeat(3, 1fr);
}

.restaurants__grid-layout a { 
    text-decoration: none;
}

.restaurants-card {
    border-radius: 8px;
    padding: 0 16px;
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
}

.restaurants-card__image-ibg {
    border-radius: 32px;
    overflow: hidden;
    padding-bottom: 79%; 
    position: relative;
}

.restaurants-card__image-ibg img {
    object-fit: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
    width: 100%;
}

.restaurants-card__image-ibg img:hover {
    transform: scale(1.05);
}

.restaurants-card__content {
    padding: 8px 0;
}
.restaurants-card__content svg {
    fill: var(--primaryColor);
}

.restaurants-card__content h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3333;
    color: var(--text-color);
}

.restaurants-card__content p {
    align-items: center;
    color: var(--primaryColor);
    display: flex;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    line-height: 24px;
}
@media (max-width: 992px) {
    .restaurants__grid-layout {
        grid-template-columns: repeat(2, 1fr);
    }
}
.timer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.timer p {
    padding: 5px 0 0 5px;
    position: relative; 
    margin-top: -25px;
    background: var(--background-color);
    border-radius: 16px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700; 
    left: 10px;
}
@media (max-width: 650px) {
    .restaurants__grid-layout {
        grid-template-columns: repeat(1, 1fr);
    }
    .restaurants-card__image-ibg {
        border-radius: 32px;
        overflow: hidden;
        padding-bottom: 50.2793296089%;
        position: relative;
    }
    .timer { 
        align-items: center;
    }
    .timer p {
        padding: 2px 8px;  
        left: -16px;
        margin-top: -60px;
    }
}
</style>