import axios from 'axios';

const API_URL = 'https://api.getorder.biz/api/v2/pwa/data/locations';
const AUTH_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5nZXRvcmRlci5iaXovYXBpL3YyL3B3YS9nZXRvcmRlciIsImlhdCI6MTczMDgyNzcyMSwiZXhwIjoxNzMzNDE5NzIxLCJuYmYiOjE3MzA4Mjc3MjEsImp0aSI6IkQ4WUdVUGYxSFRCaldESDAiLCJzdWIiOiI0MToxOCIsInBydiI6ImE2MmRkN2Q5MDk2NTFlNjJjYjAwMzMzOWI0NWUzOGZiMWZkOTg5YmIiLCJ1c2VyX2lkIjo0MSwidXNlcm5hbWUiOiJnZXRvcmRlciIsIm5hbWUiOiJTZXJnIENoYWlrYSJ9.2y5u8gu3837QEQK_A8C2iPi25iGcedFSTwfPFD6Rp9Q'; 

export async function fetchLocations() {
  try {
    const response = await axios.get(API_URL, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching locations:', error);
    throw error;
  }
}



[
  {
      "id": 6928,
      "uniq_id": "Waire",
      "title": "Waire demo",
      "slides": [],
      "main_photo": {
          "webp": "https://files.getorder.biz/pwa_rest/6928/image/18/600/600/rest_6928.webp?t=202411091528",
          "png": "https://files.getorder.biz/pwa_rest/6928/image/18/600/600/rest_6928.png?t=202411091528"
      },
      "params": {
          "is_catalog": false,
          "show_review": false,
          "description": ""
      },
      "restaurant": {
          "id": 5786,
          "uniq_id": "7bdcb54e-1986-11ee-b1f6-0242c0a8e003",
          "address": "",
          "title": "Bar",
          "provider": "sub_waiter",
          "lat": "",
          "lng": ""
      },
      "city": {
          "id": 0,
          "uniq_id": null,
          "title": "Kiev"
      }
  },
  {
      "id": 5089,
      "uniq_id": "go",
      "title": "Мій ресторан",
      "slides": [
          {
              "webp": "https://files.getorder.biz/pwa_slide/1093535/image_id/18/1500/500/rest.webp?t=202411091528",
              "png": "https://files.getorder.biz/pwa_slide/1093535/image_id/18/1500/500/rest.png?t=202411091528",
              "title": "cosmopolite1.jpg",
              "id": 1093535
          },
          {
              "webp": "https://files.getorder.biz/pwa_slide/1093536/image_id/18/1500/500/rest.webp?t=202411091528",
              "png": "https://files.getorder.biz/pwa_slide/1093536/image_id/18/1500/500/rest.png?t=202411091528",
              "title": "cosmopolite5.jpg",
              "id": 1093536
          }
      ],
      "main_photo": {
          "webp": "https://files.getorder.biz/pwa_rest/5089/image/18/600/600/rest_5089.webp?t=202411091528",
          "png": "https://files.getorder.biz/pwa_rest/5089/image/18/600/600/rest_5089.png?t=202411091528"
      },
      "params": {
          "is_catalog": false,
          "show_review": false,
          "description": "<h2>Про ресторан</h2><p><br></p><h2>Every day is a pizza day</h2><p>І ми щиро віримо у це, але сьогодні в Міжнародний День Піци просто хочемо подякувати, що ви з нами поруч! Що святкуєте дні, що обираєте улюблену піцу або ж навпаки - довіряєте нам на відриваєте нові горизонти смаків з нашими сезонними новинками від шефа. Що їсте в центрі, що замовляєте доставку, що ділите з нами вечори на @kyivfoodmarket тощо.</p><p>Дякуємо, ваша mimimi team!</p><p><br></p><p>Чекаємо щодня на Басейній 1/2</p>"
      },
      "restaurant": {
          "id": 6441,
          "uniq_id": "1",
          "address": "вулиця Райдужна, Київ, 02000",
          "title": "GetOrder Центр",
          "provider": "sub_poster",
          "lat": "50.4833383000",
          "lng": "30.5855502000"
      },
      "city": {
          "id": 2107,
          "uniq_id": "Tbiliso",
          "title": "Tbiliso"
      }
  }
]