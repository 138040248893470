export default {
  namespaced: true,
  state: () => ({
    isSidebarOpen: false,
  }),
  mutations: {
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    closeSidebar(state) {
      state.isSidebarOpen = false;
    },
    openSidebar(state) {
      state.isSidebarOpen = true;
    },
  },
  actions: {
    toggleSidebar({ commit }) {
      commit('toggleSidebar');
    },
    closeSidebar({ commit }) {
      commit('closeSidebar');
    },
    openSidebar({ commit }) {
      commit('openSidebar');
    },
  },
  getters: {
    isSidebarOpen: (state) => state.isSidebarOpen,
  },
};
