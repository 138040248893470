import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import MarketPage from '../views/MarketPage.vue';

const routes = [
    {
      path: '/',
      name: 'Home',
      component: HomePage
    },
    {
      path: '/market/:slug',
      name: 'MarketPage',
      component: MarketPage
    }
];


const router = createRouter({
    history: createWebHistory(),
    routes
  });
  
export default router;