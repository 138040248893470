<template>

  <div  class="banner-slider__container">

    <div class="_container">
      <Carousel
      ref="carousel"
      :autoplay="10000"
      :wrap-around="true"
      :loop="true"
      :transition="500"
      navigationEnabled
      class="carousel"
    >
      <Slide v-for="(item, index) in slides" :key="index">
        <div class="slider__slide">
          <picture>
            <source :srcset="item.webp" type="image/webp">
            <source :srcset="item.png" type="image/png">
            <img :src="item.png" class="banner_image">
          </picture>  
        </div>
      </Slide>
      <template #addons>
        <button @click="prevSlide" class="carousel-button prev">‹</button>
        <button @click="nextSlide" class="carousel-button next">›</button>
      </template>
    </Carousel>

    </div>
  
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'HomeSlider',
  props: ['slides'],
  components: {
    Carousel,
    Slide,
  },
  setup() {
    const carousel = ref(null);

    const nextSlide = () => {
      if (carousel.value) {
        carousel.value.next();
      }
    };

    const prevSlide = () => {
      if (carousel.value) {
        carousel.value.prev();
      }
    };

    return {
      carousel,
      nextSlide,
      prevSlide,
    };
  },
});
</script>

<style lang="scss" scoped>

.banner-slider__container {
  margin-top: 22px;
}
@media (max-width: 768px){ 
  .banner-slider__container {
    display: none;
  } 
}
.carousel * {
    width: 100%;
    height: auto;
  }
.carousel img {
  align-items: center;
  width: calc(100% - 20px) !important;
  margin-left: 10px;
  object-fit: cover;
  height: 320px;
  border-radius: 32px;
}



.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--back-navigations-btn);
  color: var(--color-navigations-btn);
  border: none;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-button.prev {
  left: 16px;
}

.carousel-button.next {
  right: 16px;
}

</style>
